@import "../../../data/styles.css";

.project-white {
	background-color: white;
	mix-blend-mode: normal;
	border-radius: 20px;
	height: 86%;
}

.project-white a {
	text-decoration: none;
}

.project-white:hover {
	background: white;
	opacity: 1;
	transition: background-color 0.3s ease-in-out;
}

.project-white:hover .project-link-white {
	color: var(--link-color);
	transition: color 0.3s ease-in-out;
}

.project-container-white {
	padding: 6px;
}

.project-logo-white {
	width: 30px;
}

.project-logo-white img {
	width: 100%;
}

.project-title-white {
	font-family: var(--secondary-font);
	color: black;
	text-align: center;
	font-size: 16px;
	padding-top: 15px;
	margin-bottom: 1em;
	font-weight: 700;
}

.project-description-white {
	color: var(--secondary-color);
}

.project-link-white {
	display: flex;
	align-items: center;
	color: var(black);
	font-size: 12px;
	padding-top: 20px;
}

.project-link-icon-white {
	padding-left: 5px;
	font-size: 13px;
}

.project-link-text-white {
	padding-left: 20px;
	font-weight: 700;
}

@media (max-width: 600px) {
	.project {
		height: 300px;
	}
}

.text-under {
	font-family: var(--secondary-font);
	color: white;
	text-align: center;
	font-size: 16px;
	padding-top: 15px;
	margin-bottom: 1em;
	font-weight: 400;
}

.project-description-image-white {
	height: 300px;
	display: flex;
}

.project-description-image-white img {
	top:50%;
    width: auto;
	max-width: 100%;  /* Set the image width to be 90% of its container */
    display: block;  /* Ensures the image is block-level for proper spacing */
    margin: auto;  /* Centers the image within its container */
	max-height: 100%;  /* Ensures the image height does not exceed its container */
    object-fit: cover;  /* Adjusts the image's fit within its bounds without stretching */
}

