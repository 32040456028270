.projects-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.projects-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
	z-index: 999;
}

.projects-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
	padding-top: 120px;
}

.projects-title {
	color: var(--primary-color);
	font-family: var(--secondary-font);
	font-size: 45px;
	font-weight: 700;
	justify-content: center;
	text-align: center;
}

.project-title {
	font-family: var(--secondary-font);
	color: var(--primary-color);
	font-size: 16px;
	padding-top: 15px;
	margin-bottom: 1em;
	font-weight: 700;
}

.project:hover {
	background: #101010;
	opacity: 1;
	transition: background-color 0.3s ease-in-out;
}

@media (max-width: 600px) {
	.projects-container {
		padding-top: 60px;
	}

	.projects-project {
		width: calc(100% / 2);
	}
}
