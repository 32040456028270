

.page-content-midtermmixer {
	background-color: white;
	position: relative;
}

.page-content-midtermmixer:before,
.page-content-midtermmixer:after {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	width: calc((100% - 1200px) / 2);
	background-color: #fafafa;
	border: 1px solid #fafafa;
}

.page-content-midtermmixer:before {
	left: 0;
	z-index: 1;
}

.page-content-midtermmixer:after {
	right: 0;
	z-index: 1;
}
.projects-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.projects-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
	z-index: 999;
}

.midtermmixer-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
	padding-top: 120px;
}

.midtermmixer-title {
	color: var(#27272a);
	font-family: var(--secondary-font);
	font-size: 35px;
	font-weight: 700;
	justify-content: center;
	text-align: left;
    padding-bottom: 15px;
    padding-top: 120px;
}

.midtermmixer-software-title {
    padding-top: 15px;
    color: var(#27272a);
	font-family: var(--secondary-font);
	font-size: 25px;
	font-weight: 700;
	justify-content: center;
	text-align: center;
}
.midtermmixer-content {
    width: 100%;
    max-width: 100%;
    justify-content: center;
    align-items: center;
}

.midtermmixer-content img {
    max-width: 100%;
    height: auto; /* Maintain aspect ratio */
}

.midtermmixer-software{
	padding-top: 15px;
	padding-bottom: 40px;
	display: flex;
	justify-content: center;
    align-items: center;
}

.midtermmixer-software img {
	max-width: 200px;
	height: auto; /* Maintain aspect ratio */
}

/* Style for the two-column layout */
.midtermmixer-columns {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 40px;
    padding-top: 20px;
}

/* Left column (35% width) */
.midtermmixer-left-column {
    flex-basis: 100%;
     /* Optional gap between columns */
}


/* Adjust styles if needed for both columns' children */

/* Wrapper for the link */
.link-wrapper-big {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px; /* Adjust size as needed */
    height: 60px;
    margin: 10px; /* Space between links */
    border-radius: 50%; /* Circular wrapper */
    background-color: #f0f0f0; /* Neutral background */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, background-color 0.3s; /* Smooth hover effects */
    text-decoration: none; /* Remove default link styling */
}

/* Hover effect for the link wrapper */
.link-wrapper-big:hover {
    transform: scale(1.1); /* Slight zoom on hover */
    background-color: #e1e1e1; /* Subtle background change on hover */
}

/* Icon container */
.big-link-icon {
    color: #333; /* Default icon color */
    font-size: 2rem; /* Adjust size as needed */
    transition: color 0.3s; /* Smooth color transition */
    display: flex; /* Ensure icon is treated as a flex item */
    align-items: center; /* Vertically center the icon */
    justify-content: center; /* Horizontally center the icon */
    height: 100%; /* Ensure it fills the parent container */
    width: 100%; /* Ensure it fills the parent container */
}


/* Hover effect for icons */
.link-wrapper-big:hover .big-link-icon {
    color: #e4405f; /* Instagram color (pinkish-red) for Instagram links */
    /* Adjust color dynamically if multiple icons */
}

/* YouTube-specific styles (optional, if needed for distinction) */
.link-wrapper-big.youtube .big-link-icon {
    color: #FF0000; /* YouTube red */
}

.link-wrapper-big.youtube:hover {
    background-color: rgba(255, 0, 0, 0.1); /* Light red background */
}

/* YouTube-specific styles (optional, if needed for distinction) */
.link-wrapper-big.projlink .big-link-icon {
    color: #FF0000; /* YouTube red */
}

.link-wrapper-big.projlink:hover {
    background-color: rgba(255, 0, 0, 0.1); /* Light red background */
}
.midtermmixer-logo-title {
	display: flex;
	align-items: center; /* Vertical centering */
	gap: 20px; /* Adds space between child elements */

}

.midtermmixer-vertical-images {
    display: flex; /* Enable flex layout */
    justify-content: space-between; /* Space out images */
    flex-wrap: wrap; /* Wrap images to prevent overflow if needed */
    width: 100%; /* Ensure the container fits within the page */
    box-sizing: border-box; /* Include padding and border in width calculations */
}

.bottom-image {
    flex: 1; /* Make all images equally share available space */
    width: 20%; /* Ensure images don't grow beyond their container */
    height: auto; /* Maintain aspect ratio */
    object-fit: contain; /* Ensure the images fit within their space */
    padding: 0 5px; /* Optional: Add spacing around the images */
}


.midtermmixer-images {
    display: grid;
    grid-template-rows: auto; /* 3 rows */
    grid-template-columns: 69% 31%; /* Top row custom proportions */
    gap: 10px; /* Space between images */
    width: 100%; /* Take full width of the parent */
    margin: 0 auto; /* Center the grid container */
    box-sizing: border-box; /* Include padding and borders in width calculation */
    border: none;
}

.grid-image {
    width: 100%; /* Full width of grid cell */
    height: auto; /* Maintain aspect ratio */
    object-fit: contain; /* Ensure the entire image is visible */
    border: none;
}

/* Top row */
.midtermmixer-images img:nth-child(1) {
    grid-row: 1;
    grid-column: 1; /* Left image */
}

.midtermmixer-images img:nth-child(2) {
    grid-row: 1;
    grid-column: 2; /* Right image */
}

.midtermmixer-bottom-images {
    padding-top: 10px;
    display: grid;
    grid-template-rows: auto auto; /* 2 rows */
    grid-template-columns: 50% 50%; /* Equal proportions */
    gap: 10px; /* Space between images */
    width: 100%; /* Full width */
    margin: 0 auto; /* Center the grid */
    box-sizing: border-box; /* Include padding and borders in size calculation */
}

.midtermmixer-bottom-images .grid-image {
    width: 100%; /* Full width of grid cell */
    height: auto; /* Maintain aspect ratio */
    object-fit: contain; /* Ensure the entire image is visible */
    border: none;
}

/* Specific layout for bottom images */
.midtermmixer-bottom-images img:nth-child(1) {
    grid-row: 1;
    grid-column: 1; /* Top-left image */
    border: 2px solid black;
}

.midtermmixer-bottom-images img:nth-child(2) {
    grid-row: 1;
    grid-column: 2; /* Top-right image */
}

.midtermmixer-bottom-images img:nth-child(3) {
    grid-row: 2;
    grid-column: 1; /* Bottom-left image */
}

.midtermmixer-bottom-images img:nth-child(4) {
    grid-row: 2;
    grid-column: 2; /* Bottom-right image */
    border: 2px solid black;
}