.footer {
    display: flex;
    justify-content: center; /* Center the images container */
    padding-bottom: 25px;
    padding-top: 25px;
}

.footer-images {
    display: flex;
    justify-content: space-around; /* Evenly space out images */
    width: 100%;
}

.footer-image {
    width: 60px; /* Example size, adjust as necessary */
    height: 60px; /* Maintain aspect ratio if desired */
    object-fit: cover; /* Ensures images cover the space without distortion */
}

.homepage-social-icon {
    margin: 0 15px; /* Adjust the left and right margins to control spacing */
}


/* Responsive adjustments */
@media (max-width: 600px) {
    .footer-images {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
    .footer-image {
        margin: 5px;
    }
}
