@import "../../../data/styles.css";


.portfolio-footer {
    display: flex;
    justify-content: center; /* Center the images container */
    padding-bottom: 25px;
    padding-top: 25px;
}

.portfolio-footer-images {
    display: flex;
    justify-content: space-around; /* Evenly space out images */
    width: 100%;
}

.portfolio-footer-image {
    width: 60px; /* Example size, adjust as necessary */
    height: 60px; /* Maintain aspect ratio if desired */
    object-fit: cover; /* Ensures images cover the space without distortion */
}

.portfolio-homepage-social-icon {
    margin: 0 15px; /* Adjust the left and right margins to control spacing */
    font-size: 25px;
    transition: color 0.2s ease-in-out;
    color: black;
}

.portfolio-page-footer {
	position: block;
}

.portfolio-page-footer::before {
	content: "";
	position: absolute;
	left: 0;
	right: 0;
}


/* Responsive adjustments */
@media (max-width: 600px) {
    .portfolio-footer-images {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
    .portfolio-footer-image {
        margin: 5px;
    }
}

.project-link {
	display: flex;
	align-items: center;
	color: var(--secondary-color);
	font-size: 12px;
	padding-top: 20px;
}

.link-second-wrapper {
    display: flex;
    align-items: center;
}

.link-wrapper {
    text-decoration: none;
    display: flex;
    align-items: center;
    padding-bottom: 15px;
    margin-right: 35px;
}

.project-link-icon {
    margin-right: 10px; /* Space between the icon and the text */
    font-size: 15px;
    color: var(--link-color);
}

.project-link-icon-special {
    margin-right: 10px; /* Space between the icon and the text */
    font-size: 15px;
    color: var(--special-color);

}

.project-link-text {
    font-weight: 700;
    font-size: 15px;
    color: var(--link-color);
}

.project-link-text-special {
    font-weight: 700;
    font-size: 15px;
    color: var(--special-color);
}

