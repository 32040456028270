

.page-content-emotionalobject {
	background-color: white;
	position: relative;
}

.page-content-emotionalobject:before,
.page-content-emotionalobject:after {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	width: calc((100% - 1200px) / 2);
	background-color: #fafafa;
	border: 1px solid #fafafa;
}

.page-content-emotionalobject:before {
	left: 0;
	z-index: 1;
}

.page-content-emotionalobject:after {
	right: 0;
	z-index: 1;
}
.projects-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.projects-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
	z-index: 999;
}

.emotionalobject-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
	padding-top: 120px;
}

.emotionalobject-title {
	color: var(#27272a);
	font-family: var(--secondary-font);
	font-size: 45px;
	font-weight: 700;
	justify-content: center;
	text-align: left;
    padding-bottom: 25px;
}

.emotionalobject-software-title {
    padding-top: 50px;
    color: var(#27272a);
	font-family: var(--secondary-font);
	font-size: 25px;
	font-weight: 700;
	justify-content: center;
	text-align: left;
}
.emotionalobject-content {
    width: 100%;
    max-width: 100%;
    justify-content: center;
    align-items: center;
}

.emotionalobject-content img {
    max-width: 100%;
    height: auto; /* Maintain aspect ratio */
	border: 2px solid black;

}

.emotionalobject-software{
	padding-top: 15px;
	padding-bottom: 40px;
	display: flex;
}

.emotionalobject-software img {
	max-width: 200px;
	height: auto; /* Maintain aspect ratio */
}

/* Style for the two-column layout */
.emotionalobject-columns {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-top: 120px;
    padding-bottom: 40px;
}

/* Left column (35% width) */
.left-column {
    flex-basis: 42%;
    padding-right: 20px; /* Optional gap between columns */
}

/* Right column (65% width) */
.right-column {
    flex-basis: 58%;
    padding-left: 20px; /* Optional gap between columns */
}

/* Adjust styles if needed for both columns' children */
